var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"imS0h9TB3cFa44q28jZHM"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/ai";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const dsn = process.env.NEXT_PUBLIC_DISABLE_SENTRY_DSN ? null : (SENTRY_DSN || 'https://b53812cb12484773beca2e516171e5e2@o4504909978009600.ingest.sentry.io/4504910009073664')

Sentry.init({
  // dsn: SENTRY_DSN || 'https://b53812cb12484773beca2e516171e5e2@o4504909978009600.ingest.sentry.io/4504910009073664',
  dsn: dsn,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreUrls: [/api\/user\/get/],
  ignoreErrors: ['Request failed with status code 401']
});
